
/*(function($) {
  // Site title
  wp.customize('blogname', function(value) {
    value.bind(function(to) {
      $('.brand').text(to);
    });
  });
})(jQuery);
*/

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, document, window) {
  'use strict';

  var $window, $body, $document;

  function assignBootstrapMode() {
    var mode;

    switch (true) {
      case $window.width() >= 1300:
        mode = 'mode-lg';
        break;
      case $window.width() >= 1024:
        mode = 'mode-md';
        break;
      case $window.width() >= 768:
        mode = 'mode-sm';
        break;
      default:
        mode = 'mode-xs';
        break;
    }

    $body.removeClass('mode-xs mode-sm mode-md mode-lg').addClass(mode);
  }

  function goToSection() {

    $('a[href*="#"]:not([data-toggle])').on('click', function (e) {

      var divId = this.href.split("#")[1], $div = $('#' + divId);

      if ($div.length) {
        e.preventDefault();
        $('body, html').stop().animate({scrollTop: $div.offset().top}, 500, 'swing');
      }

    });
  }

  function inithistoryBack() {

    function historyBack(e) {
      //Referrer ao dominio atual?
      if (document.referrer.indexOf(window.location.host) !== -1) {
        //Tem itens no history back?
        if (window.history.length > 1) {
          e.preventDefault();
          window.history.back();
        }
      } else {
        window.location = URL_PATH;
      }
    }

    $document.on('click', 'a[data-back-link]', historyBack);
  }


  function initMenuMobile() {

    $document.on('show.bs.collapse', '.navbar-collapse', function () {
      $('.navbar-toggle').addClass('open');
    }).on('hide.bs.collapse', '.navbar-collapse', function () {
      $('.navbar-toggle').removeClass('open');
    });

  }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        $('a[rel~=external]').attr('target', '_blank');

        $window = $(window);
        $document = $(document);
        $body = $('body');

        goToSection();

        objectFitImages();

        $window.on('resize', $.throttle(150, assignBootstrapMode));
        assignBootstrapMode();

        $('.item-embed').fitVids();

        inithistoryBack();

        initMenuMobile();

        $( '[data-chourico-search]' ).on('click', function(e){
          e.preventDefault();

          var $form = $( $(this).data('chourico-search') );

          if($form.hasClass('active') && $form.find('input').val() !== ''){
            $form.find('form').submit();
          } else {
            $form.toggleClass('active');
          }
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'contato': {
      init: function () {

        var maskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
          },
          options = {
            onKeyPress: function (val, e, field, options) {
              field.mask(maskBehavior.apply({}, arguments), options);
            }
          };

        $('[data-chourico-mask="phone"]').mask(maskBehavior, options);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery, document, window); // Fully reference jQuery after this point.

;
/**
 * Plugin responsável por fazer a contagem de cliques em banners quando se usa AdRotate. A chamada padrão dele,
 * usando o próprio diretório do plugin não funciona por que ele não adiciona o jQuery como dependência, sendo assim,
 * quebra o funcionamto. :)
 */
(function($) {
  $(document).ready(function() {

    var AJAX_URL = URL_PATH + '/wp-admin/admin-ajax.php';

    $(document).on('click', 'a.gofollow', function(){

      var tracker = $(this).attr("data-track");
      var debug = $(this).attr("data-debug");

      $.post(AJAX_URL, {
        'action':'adrotate_click',
        'track':tracker
      });

      if(debug === 1){
        alert('Tracker: ' + tracker + '\nclick_object.ajax_url: '+AJAX_URL);
      }
    });
  });
}(jQuery));
;(function ($, document) {

    var $document = $(document), submitText = '';

    function submitForm(e) {
        e.preventDefault();

        var $form = $(e.currentTarget),
            $submit = $form.find('input[type=submit], button').first(),
            action = $form.attr('action'),
            processor = $form.data('chourico-ajaxform'),
            url = action + 'mailer/index.php?sender=' + encodeURI(processor);

        function enableSubmit() {

            var method;

            if ($submit.is('input')) {
                method = 'val';
            } else {
                method = 'text';
            }

            $submit.prop('disabled', false);
            $submit[method](submitText);
        }

        function disableSubmit() {
            var val, method;

            if ($submit.is('input')) {
                method = 'val';
            } else {
                method = 'text';
            }

            $submit.prop('disabled', true);
            submitText = $submit[method]();

            $submit[method]('Enviando...');
        }

        function preparesAlert(data){

            var type = '',
                message = '',
                $alert = $form.children('.chourico-ajaxform-alert');

            if(!data){
                type = 'info';
                message = 'Resultado inesperado! :(';
            } else {
                type = data.messageType;
                message = data.messageText;
            }

            if (!$alert.length) {
                $alert = $('<div class="alert chourico-ajaxform-alert" />');
                $form.prepend($alert);
            }

            $alert.removeClass('alert-info alert-success alert-danger').addClass('alert-' + type).html(message);

            enableSubmit();
        }

        function onError(data) {

            if (data.responseText) {
                data.messageType = 'info';
                data.messageText = '<strong>Falha recebida ao enviar mensagem:</strong><br/><br/>' + data.responseText;
            }

            preparesAlert(data);
        }    

        function beforeSubmit() {
            if ($submit.prop('disabled')) {
                return false;
            }

            disableSubmit();
        }

        function onSuccess(data) {

            if (data.messageType === 'success') {
                $form.clearForm();
            }

            preparesAlert(data);
        }

        $form.ajaxSubmit({
            url: url,
            dataType: 'json',
            type: 'post',
            beforeSubmit: beforeSubmit,
            success: onSuccess,
            error: onError,
        });
    }

    $document.on('submit', 'form[data-chourico-ajaxform]', submitForm);
})(jQuery, document);

;
if ( typeof $.throttle === 'undefined' ) {
  throw '$.throttle is not present';
}

(function ($, document) {
  'use strict';

  var $document = $(document),
    $window = $( window ),
    $body = $( 'body' );

  var $footer = $('[data-chourico-load-more-sitefooter]'),
    heigthFooter = $footer.length ? $footer.outerHeight() : 0,
    ajaxUrl = chouricoApiSettings.apiPath + 'v1/posts/',
    $container = $('[data-chourico-load-more-postlist]'),
    searchString = $container.data('chourico-load-more-search'),
    postType = $container.data('chourico-load-more-posttype'),
    page = $container.data('chourico-load-more-page'),
    bottomOffset = $container.data('chourico-load-more-bottom-offset'),
    $imgLoading = $('[data-chourico-load-more-loading-img]'),
    calling = false,
    endList = !!$container.data('chourico-load-more-no-data');

  function windowScroll(e) {

    if ($window.scrollTop() >= ($document.height() - $window.height() - heigthFooter - bottomOffset) && !calling && !endList) {

      calling = true;

      $.ajax({
        url: ajaxUrl,
        type: 'POST',
        data: {
          page: page + 1,
          search: searchString,
          post_type: postType,
        },
        beforeSend: function ( xhr ) {
          $imgLoading.show();
          $container.trigger('chourico-load-more-page-loading');
          xhr.setRequestHeader( 'X-WP-Nonce', chouricoApiSettings.nonce );
        },
        error: function (response) {
          console.log(response);
        },
        success: function (response){

          if(response.length) {

            var templatePost = $('#post-template').html();
            var templateAd = $('#ad-template').html();

            $.each(response, function (index, value) {

              Mustache.parse(templatePost);
              Mustache.parse(templateAd);

              if(value.type === 'post') {
                value["content"]["isVideo"] = value.content.post_type === 'video';
              }

              var rendered = value.type === 'ad' ? Mustache.render(templateAd, {'html': value.content}) : Mustache.render(templatePost, value.content);
              $container.append(rendered);

            });

            page = page + 1;
          } else {
            endList = true;
            $('[data-chourico-load-more-nomore]').html('Não há mais nada a ser carregado! =/');
          }

        },
        complete: function () {
          $imgLoading.hide();
          $container.trigger('chourico-load-more-page-complete');
          calling = false;
        }
      });

    }

  }

  if($container.length) {
    $window.on('scroll', $.throttle(500, windowScroll)).scroll();
  }

})(jQuery, document);
